import * as types from '../constants/ActionTypes';

export function homeGoodPricingFetching() {
  return {
    type: types.HOME_GOOD_PRICING_GET + types.FETCHING
  };
}

export function homeGoodPricingFail() {
  return {
    type: types.HOME_GOOD_PRICING_GET + types.FAIL
  };
}

export function homeGoodPricingReceived(payload) {
  return {
    type: types.HOME_GOOD_PRICING_GET + types.SUCCESS,
    payload
  };
}
