import React from 'react';
import {translate} from '../services';
import {isNotUndefined} from './validation';
import {CART_CONTENT_CHANGED} from '../constants/Codes';
import Icon from '../components/Reusable/Icon/Icon';
import {defaultBasePrice} from './fabrics';

export function createButtonContent(content) {
  return (
    <React.Fragment>
      <Icon iconName='checkmark'/>
      {content}
    </React.Fragment>);
}

export function getAddToCartButtonContent(contentType, orderItemIdFromQuery) {
  const updateCartContent = translate('fabric.content.updated');
  const addToCartContent = translate('fabric.content.added');

  switch (contentType) {
    case CART_CONTENT_CHANGED:
      return isNotUndefined(orderItemIdFromQuery) ? createButtonContent(updateCartContent) : createButtonContent(addToCartContent);
    default:
      return isNotUndefined(orderItemIdFromQuery) ? translate('fabric.content.updateCart') : translate('fabric.content.addToCart');
  }
}

export function getWallpaperCalculatorAddToCartButtonContent(contentType, rawItemPrice, currency) {
  const addToCartContent = `${translate('fabric.content.added')} ${defaultBasePrice(rawItemPrice, currency)}`;

  switch (contentType) {
    case CART_CONTENT_CHANGED:
      return createButtonContent(addToCartContent);
    default:
      return `${translate('fabric.content.addToCart')} ${defaultBasePrice(rawItemPrice, currency)}`;
  }
}
