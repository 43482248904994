import {colorwaysFail, colorwaysFetching, colorwaysReceived} from '../../../actions/colorways';
import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {redirectCodes} from '../../../constants/redirectCodes';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {Colorway, ColorwaysData, ColorwaysFunc, ColorwaysParams} from '../../../shapes/colorways';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';
import {isArray, isEmpty, isUndefined} from '../../../utils/validation';

// Add current colorway to colorways list
// TODO SP-15021 remove when new colorways API will be ready
export function completeColorways(colorways: Colorway[], selectedColorway?: Colorway, customThumbnail?: string): Colorway[] {
  if (isUndefined(selectedColorway)) return colorways;
  if (!isArray(colorways)) return [];
  if (isEmpty(colorways)) return [];

  const slicedColorways = colorways.length === 24 ? colorways.slice(0, 23) : colorways;

  const allColorways = [...slicedColorways, {
    ...selectedColorway,
    thumbnail: customThumbnail || selectedColorway.thumbnail
  }];

  return allColorways.sort(function(a, b) {
    if (a.title > b.title) {
      return 1;
    }
    if (a.title < b.title) {
      return -1;
    }

    return 0;
  });
}

export default function fetchColorways(): ColorwaysFunc {
  return (dispatch, getState) => {
    const state = getState();
    const {routingData: {routesProps: {productType}, routesParams}} = state;

    const designId = parseInt(routesParams?.designId, 10);

    dispatch(colorwaysFetching());

    const params: ColorwaysParams = {
      product_type: productType
    };

    return request<ColorwaysData>(state, {
      url: `${selectAlpenroseHost(state)}/design/${designId}/colorways`,
      params
    })
      .then(({data}) => {
        if (!isEmpty(data.data.colorways)) {
          dispatch(colorwaysReceived(data));
        } else {
          dispatch(colorwaysReceived({
            data: {
              colorways: undefined
            }
          }));
        }
      })
      .catch((error: RequestError) => {
        dispatch(colorwaysFail());
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(error));
        }
        console.log('Error caught in the `fetchColorways` function', error); // eslint-disable-line
      });
  };
}
