import type {Dispatch, AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {pageUpdate} from '../../actions/pageSetup';
import {State} from '../../store/initialState';


export function initWideLayout(): ThunkAction<void, State, unknown, AnyAction> {
  return (dispatch: Dispatch) => {
    dispatch(pageUpdate({
      contentInnerExtensions: 'x-1294'
    }));
  };
}
