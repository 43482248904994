import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {setSubstrate} from '../../../actions/addToCart';
import {FABRIC} from '../../../constants/Codes';
import {initTitle} from '../../../entities/layout/initTitle';
import {State} from '../../../store/initialState';
import {pdpSelectedSubstrate} from '../../../utils/stateToPropsHelpers';


export default function initFabricPage(): ThunkAction<void, State, unknown, AnyAction> {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const {routesProps: {productType}} = state.routingData;

    if (productType !== FABRIC) return;

    const type = pdpSelectedSubstrate(state, FABRIC);

    dispatch(setSubstrate(type));
    initTitle('fabric.head.title');
  };
}

export const initFabricTitle = (): ThunkAction<void, State, unknown, AnyAction> => initTitle('fabric.head.title');
