import get from 'lodash/get';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {fabricPricingFail, fabricPricingFetching, fabricPricingReceived} from '../../../actions/addToCart';
import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {FABRIC} from '../../../constants/Codes';
import {CURRENCY} from '../../../constants/Parameters';
import {redirectCodes} from '../../../constants/redirectCodes';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {MeasurementType} from '../../../shapes/measurement';
import {Promo} from '../../../shapes/promo';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';
import {
  pdpSelectedSubstrate,
  quantityFromOrderItem,
  queryParamsOverrideUserPreferences
} from '../../../utils/stateToPropsHelpers';


export interface FabricPricingResponse {
  data: {
    afterpay: {
      available: boolean;
    };
    analytics: {
      FAT_QUARTER_YARD: { sku: string };
      TEST_SWATCH_YARD: { sku: string };
      YARD: { sku: string };
      parent_sku: string;
    };
    currency: string;
    default_size: string;
    fabric_code: string;
    measurement_system: MeasurementType;
    price_per: {[key: string]: number | null};
    pricing: {
      [key: string]: {
        price: number;
        price_undiscounted: number;
        discounts_applied: boolean;
        chunkable?: boolean;
      };
    };
    promo: Promo;
    quantity: number;
    tax_rate: number;
    turnarounds: {
      STANDARD: {min_days: number; max_days: number};
    };
  };
}

interface FabricPricingParams {
  quantity: number;
  shipping_country: string;
  currency: string;
  measurement_system: MeasurementType;
  design_id: number | string;
  order_item_id?: number | string;
}

export type FabricPricingFuncType = ThunkAction<Promise<FabricPricingResponse | void>, State, unknown, AnyAction>;

export default function fetchFabricPricing(): FabricPricingFuncType {
  return (dispatch, getState) => {
    const state = getState();
    const {routesParams: {designId}, routesProps: {productType}} = state.routingData;

    if (productType !== FABRIC || state.addToCart.isFetching) return Promise.resolve();

    dispatch(fabricPricingFetching());

    const {
      country,
      measurementSystem: measurement_system,
      currency
    } = queryParamsOverrideUserPreferences(state);
    const substrateCode = pdpSelectedSubstrate(state, FABRIC);
    const defaultFabricCode = get(state.fabrics, 'default', '');
    const fabric = substrateCode ? substrateCode : defaultFabricCode;

    const params: FabricPricingParams = {
      quantity: state.addToCart.quantity || quantityFromOrderItem(state) || 1,
      shipping_country: country,
      [CURRENCY]: currency,
      measurement_system,
      design_id: designId,
    };

    return request<FabricPricingResponse>(state, {
      url: `${selectAlpenroseHost(state)}/pricing/fabrics/${fabric}`,
      params
    })
      .then(({data}) => {
        dispatch(fabricPricingReceived(data));

        return data;
      })
      .catch((error: RequestError) => {
        dispatch(fabricPricingFail());
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(redirectCodes.includes(error.status) ? new RequireRedirectError(error, '/', 'fetchFabricPricing') : error));
        }
        console.log('Error caught in the `fetchFabricPricing` function', error); // eslint-disable-line
      });
  };
}
