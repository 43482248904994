import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import {HOME_GOOD} from '../../../constants/Codes';
import {redirectCodes} from '../../../constants/redirectCodes';
import {fetchCartOrders} from '../../../entities/cart/api/fetchCartOrders';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';
import {selectedHomeGoodFabric} from '../../../utils/stateToPropsHelpers';

import fetchPricingForHomeGoodUsingSubstrateCode from './fetchHomeGoodData';


export interface HomeGoodPricingResponse {
  data: State['addToCart'];
}

export default function fetchHomeGoodPricing(): ThunkAction<Promise<HomeGoodPricingResponse | void>, State, unknown, AnyAction> {
  return async(dispatch, getState) => {
    let state = getState();
    const {
      routingData: {routesProps: {productType}},
      routing: {locationBeforeTransitions: {query: {order_item_id: orderItemId}}}
    } = state;

    if (!productType.startsWith(HOME_GOOD)) return Promise.resolve();

    if (process.env.REACT_APP_IS_SERVER && orderItemId) {
      await dispatch(fetchCartOrders());
      state = getState();
    }

    const fabric = selectedHomeGoodFabric(state);

    return dispatch(fetchPricingForHomeGoodUsingSubstrateCode())
      .then((blob: HomeGoodPricingResponse | void) => {
        if (blob?.data) {
          state = getState();
          const fabricFromUpdatedState = selectedHomeGoodFabric(state);
          const isSubstrateTheSame = fabricFromUpdatedState === fabric;

          if (!isSubstrateTheSame) {
            return dispatch(fetchPricingForHomeGoodUsingSubstrateCode())
              .catch((e: RequestError) => {
                if (process.env.REACT_APP_IS_SERVER) {
                  dispatch(serverError(redirectCodes.includes(e.status) ? new RequireRedirectError(e, '/', 'fetchPricingForHomeGoodUsingSubstrateCode') : e));
                } else {
                  console.log('Error caught in the `fetchHomeGoodPricing` function', e); // eslint-disable-line
                }
              });
          }
        }
      })
      .catch((e: RequestError) => {
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(redirectCodes.includes(e.status) ? new RequireRedirectError(e, '/', 'fetchHomeGoodPricing') : e));
        } else {
          console.log('Error caught in the `fetchHomeGoodPricing` function', e); // eslint-disable-line
        }
      });
  };
}
