import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {setSubstrate} from '../../../actions/addToCart';
import {WALLPAPER} from '../../../constants/Codes';
import {initTitle} from '../../../entities/layout/initTitle';
import {State} from '../../../store/initialState';
import {pdpSelectedSubstrate} from '../../../utils/stateToPropsHelpers';


export default function initWallpaperPage(): ThunkAction<void, State, unknown, AnyAction> {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const {routesProps: {productType}} = state.routingData;

    if (productType !== WALLPAPER) return;

    const type = pdpSelectedSubstrate(state, WALLPAPER);

    dispatch(setSubstrate(type));
    initTitle('wallpaper.head.title');
  };
}

