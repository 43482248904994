import isEmpty from 'lodash/isEmpty';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {WALLPAPER} from '../../../constants/Codes';
import {fetchCartOrders} from '../../../entities/cart/api/fetchCartOrders';
import {State} from '../../../store/initialState';
import {fetchPricingUsingSubstrateCode} from '../../../utils/dispatchToStateHelpers';
import {pdpSelectedSubstrate} from '../../../utils/stateToPropsHelpers';

import fetchDesign from './fetchDesign';
import {FabricPricingResponse} from './fetchFabricPricing';
import fetchWallpaperPricing from './fetchWallpaperPricing';
import {fetchWallpapers} from './fetchWallpapers';


export default function fetchAllWallpapersData(): ThunkAction<Promise<FabricPricingResponse | void>, State, unknown, AnyAction> {
  return async(dispatch, getState) => {
    let state = getState();
    const {
      routingData: {routesProps: {productType}},
      routing: {locationBeforeTransitions: {query: {order_item_id: queryOrderItemId}}}
    } = state;

    if (productType !== WALLPAPER) return Promise.resolve();

    await dispatch(fetchDesign()); // dependency to get correct substrate
    const wallpapersResponse = await dispatch(fetchWallpapers());

    if (!isEmpty(wallpapersResponse)) {
      if (process.env.REACT_APP_IS_SERVER && queryOrderItemId) {
        await dispatch(fetchCartOrders());
      }

      state = getState();
      const {
        wallpapers,
        addToCart: {isPostingMeasurements, orderItemSubstrateCodeSet},
        user: {cookies}
      } = state;
      const defaultWallpaperCode = wallpapers?.default;
      const selectedSubstrate = pdpSelectedSubstrate(state, WALLPAPER);

      const currentSubstrateIsSelectedSubstrate = !isEmpty(selectedSubstrate) && (!queryOrderItemId || orderItemSubstrateCodeSet);

      if (!isPostingMeasurements && !isEmpty(defaultWallpaperCode)) {
        if (currentSubstrateIsSelectedSubstrate) {
          return fetchPricingUsingSubstrateCode(defaultWallpaperCode, selectedSubstrate, dispatch, fetchWallpaperPricing, cookies);
        }
      }
    }
  };
}
