import * as types from '../constants/ActionTypes';

export function fabricsFetching() {
  return {
    type: types.FABRICS_GET + types.FETCHING
  };
}

export function fabricsReceived(payload) {
  return {
    type: types.FABRICS_GET + types.SUCCESS,
    payload
  };
}

export function fabricsFail(payload) {
  return {
    type: types.FABRICS_GET + types.FAIL,
    payload
  };
}

export function setFabricShopSearch(fabricShopSearch) {
  return {
    type: types.SET_FABRIC_SHOP_SEARCH,
    payload: {
      fabricShopSearch,
    },
  };
}
