import isEmpty from 'lodash/isEmpty';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';


import {FABRIC} from '../../../constants/Codes';
import {fetchCartOrders} from '../../../entities/cart/api/fetchCartOrders';
import {State} from '../../../store/initialState';
import {fetchPricingUsingSubstrateCode} from '../../../utils/dispatchToStateHelpers';
import {pdpSelectedSubstrate} from '../../../utils/stateToPropsHelpers';
import {fetchFabrics} from '../../Fabric/api/fetchFabrics';

import fetchDesign from './fetchDesign';
import fetchFabricPricing from './fetchFabricPricing';
import {WallpaperPricingResponse} from './fetchWallpaperPricing';


export default function fetchAllFabricsData(): ThunkAction<Promise<WallpaperPricingResponse | void>, State, unknown, AnyAction> {
  return async(dispatch, getState) => {
    let state = getState();
    const {
      routingData: {routesProps: {productType}},
      routing: {locationBeforeTransitions: {query: {order_item_id: queryOrderItemId}}}
    } = state;

    if (productType === FABRIC) {
      await dispatch(fetchDesign()); // dependency to get correct substrate
      const fabricsResponse = await dispatch(fetchFabrics());

      if (!isEmpty(fabricsResponse)) {
        state = getState();
        if (process.env.REACT_APP_IS_SERVER && queryOrderItemId) {
          await dispatch(fetchCartOrders());

          state = getState();
        }
        const {
          fabrics,
          addToCart: {orderItemSubstrateCodeSet},
          user: {cookies},
        } = state;
        const defaultFabricCode = fabrics?.default;
        const selectedSubstrate = pdpSelectedSubstrate(state, FABRIC);

        const currentSubstrateIsSelectedSubstrate = !isEmpty(selectedSubstrate) && (!queryOrderItemId || orderItemSubstrateCodeSet);

        if (!isEmpty(defaultFabricCode)) {
          if (currentSubstrateIsSelectedSubstrate) {
            return fetchPricingUsingSubstrateCode(defaultFabricCode, selectedSubstrate, dispatch, fetchFabricPricing, cookies);
          }
        }
      }
    }
  };
}
