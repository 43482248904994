import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import {wallpapersFail, wallpapersFetching, wallpapersReceived} from '../../../actions/wallpaper';
import request from '../../../application/api/request';
import {IMPERIAL} from '../../../constants/Measurements';
import {CURRENCY} from '../../../constants/Parameters';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {MeasurementType} from '../../../shapes/measurement';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {queryParamsOverrideUserPreferences} from '../../../utils/stateToPropsHelpers';


interface FetchWallpapersResponse {
  data: {
    currency: string;
    measurement_system: MeasurementType;
    vat_rate: number;
    lead_currency: string;
    default: string;
    wallpapers: {
      [key: string]: {
        id: number;
        pricing: Record<string, unknown>;
        sizing: Record<string, unknown>;
        max_height: Record<string, unknown>;
      };
    };
  };
}

interface FetchWallpapersParams {
  measurement_system?: MeasurementType;
  [CURRENCY]?: string;
  shipping_country: string;
}

export function fetchWallpapers(): ThunkAction<Promise<void | FetchWallpapersResponse>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    dispatch(wallpapersFetching());

    const state = getState();
    const {
      country,
      currency
    } = queryParamsOverrideUserPreferences(state);

    const params: FetchWallpapersParams = {
      measurement_system: IMPERIAL,
      currency,
      shipping_country: country
    };

    return request<FetchWallpapersResponse>(state, {
      url: `${selectAlpenroseHost(state)}/wallpapers`,
      params,
    })
      .then(({data}) => {
        dispatch(wallpapersReceived(data));

        return data;
      })
      .catch((error: RequestError) => {
        dispatch(wallpapersFail());
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(error));
        } else {
          console.log('Error caught in the `fetchWallpapers` function', error); // eslint-disable-line
        }
      });
  };
}
