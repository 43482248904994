import Cookies from 'js-cookie';

import {setSubstrate} from '../actions/addToCart';
import {serverError} from '../actions/server';
import {FABRIC, WALLPAPER} from '../constants/Codes';
import {FABRIC_CODE_COOKIE_NAME, WALLPAPER_CODE_COOKIE_NAME} from '../constants/Cookies';
import {redirectCodes} from '../constants/redirectCodes';
import {FabricPricingFuncType, FabricPricingResponse} from '../containers/Product/api/fetchFabricPricing';
import {WallpaperPricingFuncType, WallpaperPricingResponse} from '../containers/Product/api/fetchWallpaperPricing';
import {State} from '../store/initialState';

import {setSecureCookie} from './cookies';
import {RequestError} from './errors';
import {RequireRedirectError} from './redirectError';
import {isNotUndefined, isUndefined} from './validation';


type PricingFuncType = FabricPricingFuncType | WallpaperPricingFuncType;
type PricingResponseType = FabricPricingResponse | WallpaperPricingResponse;

export const fetchPricingUsingSubstrateCode = ( //eslint-disable-line
  defaultSubstrateCode: string | undefined,
  substrateCode: string | undefined,
  dispatch: any, //eslint-disable-line
  fetchPricingFunc: () => PricingFuncType,
  cookies?: State['user']['cookies'] // this argument should only be passed if this function runs on server
) => {
  const substrateIsFabric = defaultSubstrateCode && defaultSubstrateCode.includes(FABRIC);
  const cookieName = substrateIsFabric ? FABRIC_CODE_COOKIE_NAME : WALLPAPER_CODE_COOKIE_NAME;
  const isServer = process.env.REACT_APP_IS_SERVER;
  const substrateCookie = isServer && isNotUndefined(cookies) ? cookies[cookieName] : Cookies.get(cookieName);
  const substrateCookieIsDefault = substrateCookie === defaultSubstrateCode;

  dispatch(setSubstrate(substrateCode));

  return dispatch(fetchPricingFunc())
    .then(({data}: PricingResponseType) => {
      const {fabric_code: fabricCode} = data;
      const selectedSubstrateIsDefault = substrateCode === defaultSubstrateCode;
      const substrateCodeIsDefault = fabricCode === defaultSubstrateCode;
      const substrateTypeFromData = fabricCode.includes(substrateIsFabric ? FABRIC : WALLPAPER);
      const substrateAndCookieMatchType = isNotUndefined(fabricCode) ? substrateTypeFromData : false;

      if (!substrateCodeIsDefault || !substrateCookie || substrateCookieIsDefault || (selectedSubstrateIsDefault && (fabricCode !== substrateCookie))) {
        isUndefined(cookies) && substrateAndCookieMatchType && setSecureCookie(cookieName, fabricCode);
        dispatch(setSubstrate(fabricCode));
      } else {
        return dispatch(fetchPricingFunc())
          .then(({data}: PricingResponseType) => {
            isUndefined(cookies) && setSecureCookie(cookieName, data.fabric_code);
            dispatch(setSubstrate(data.fabric_code));
          })
          .catch((e: RequestError) => {
            if (process.env.REACT_APP_IS_SERVER) {
              dispatch(serverError(redirectCodes.includes(e.status) ? new RequireRedirectError(e, '/', 'fetchPricingFunc') : e));
            } else {
              console.log('Error caught in the `fetchPricingUsingSubstrateCode` function', e); // eslint-disable-line
            }
          });
      }
    })
    .catch((e: RequestError) => {
      if (process.env.REACT_APP_IS_SERVER) {
        dispatch(serverError(redirectCodes.includes(e.status) ? new RequireRedirectError(e, '/', 'fetchPricingFunc') : e));
      } else {
        console.log('Error caught in the `fetchPricingUsingSubstrateCode` function', e); // eslint-disable-line
      }
    });
};
