import {AxiosResponse} from 'axios';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {designFailed, designFetching, designReceived} from '../../../actions/design';
import {serverError} from '../../../actions/server';
import {userFavoriteDesignsReceived} from '../../../actions/userFavoriteDesigns';
import request from '../../../application/api/request';
import {SOLID} from '../../../constants/Codes';
import {redirectCodes} from '../../../constants/redirectCodes';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {DesignResponse} from '../../../shapes/design';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';
import {isNotUndefined} from '../../../utils/validation';


export default function fetchDesign(): ThunkAction<Promise<DesignResponse | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const {routesProps: {productType}} = getState().routingData;

    // we don't need to do this request for Solid PDP
    if (productType === SOLID) return Promise.resolve();

    const state = getState();
    const {design: {isFetching}} = state;

    if (isFetching) return Promise.resolve();

    const designId = parseInt(state.routingData?.routesParams?.designId, 10);

    dispatch(designFetching());

    return request<AxiosResponse<DesignResponse>>(state, {
      url: `${selectAlpenroseHost(state)}/design/${designId}`
    })
      .then(({data: dataObj}) => {
        const {data} = dataObj;

        data.description = data.description || '';
        data.short_description = data.short_description || '';

        if (data.id && isNotUndefined(data.user_favorite)) {
          dispatch(
            userFavoriteDesignsReceived({
              data: {
                user_favorites: {
                  [data.id]: data.user_favorite
                }
              }
            })
          );
        }

        dispatch(designReceived(dataObj));

        return data;
      })
      .catch((e: RequestError) => {
        dispatch(designFailed(e.status));
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(redirectCodes.includes(e.status) ? new RequireRedirectError(e, '/', 'fetchDesign') : e));
        } else {
          console.log('Error caught in the `fetchDesign` function', e); // eslint-disable-line
        }
      });
  };
}
