import get from 'lodash/get';
import {AnyAction, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {setProductSize} from '../../../actions/addToCart';
import {HOME_GOOD} from '../../../constants/Codes';
import {State} from '../../../store/initialState';
import {orderItemFromParams} from '../../../utils/stateToPropsHelpers';
import {isNotUndefined} from '../../../utils/validation';


export default function initOrderItem(): ThunkAction<void, State, unknown, AnyAction> {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const {routingData: {routesProps: {productType}}, addToCart: {productSize}} = state;

    if (!productType.startsWith(HOME_GOOD)) return;

    const orderItem = orderItemFromParams(state);

    if (isNotUndefined(orderItem?.fabric)) {
      const orderItemHomeGoodSize = get(orderItem, 'merchandise.home_good_item.home_good.code', '');

      if (!!orderItemHomeGoodSize && productSize !== orderItemHomeGoodSize) {
        dispatch(setProductSize(orderItemHomeGoodSize));
      }
    }
  };
}
