import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {SOLID} from '../../../constants/Codes';
import {initTitle} from '../../../entities/layout/initTitle';
import {State} from '../../../store/initialState';


export const initSolidPageTitle = (): ThunkAction<void, State, unknown, AnyAction> => ((dispatch, getState) => {
  const {productType} = getState().routingData.routesParams;

  if (productType !== SOLID) return;

  return initTitle('titles.solidPage.title');
});
