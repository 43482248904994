import type {Dispatch, AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {pageUpdate} from '../../actions/pageSetup';
import {translate} from '../../services';
import {State} from '../../store/initialState';


export function initTitle(title: string): ThunkAction<void, State, unknown, AnyAction> {
  return (dispatch: Dispatch) => {
    dispatch(pageUpdate({
      pageTitle: translate(title)
    }));
  };
}
