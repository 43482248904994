import get from 'lodash/get';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import {wallpaperPricingFail, wallpaperPricingFetching, wallpaperPricingReceived} from '../../../actions/wallpaper';
import request from '../../../application/api/request';
import {WALLPAPER} from '../../../constants/Codes';
import {IMPERIAL} from '../../../constants/Measurements';
import {CURRENCY} from '../../../constants/Parameters';
import {redirectCodes} from '../../../constants/redirectCodes';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {MeasurementType} from '../../../shapes/measurement';
import {AfterPay} from '../../../shapes/pricing';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';
import {quantityFromOrderItem, queryParamsOverrideUserPreferences} from '../../../utils/stateToPropsHelpers';


export interface WallpaperPricingResponse {
  data: {
    pricing: {
      [key: string]: {
        price: number;
        price_undiscounted: number;
        discounts_applied: boolean;
      };
    };
    price_per: {
      [key: string]: number;
    };
    analytics: {
      [key: string]: {
        sku: string;
      } | string;
    };
    measurement_system: MeasurementType;
    currency: string;
    quantity: number;
    tax_rate: number;
    fabric_code: string;
    default_size: string;
    turnarounds: {
      STANDARD: {
        min_days: number;
        max_days: number;
      };
    };
    afterpay: AfterPay;
  };
}

interface WallpaperPricingParams {
  measurement_system: MeasurementType;
  design_id: number | string;
  shipping_country: string;
  [CURRENCY]: string;
  quantity: number;
}

export type WallpaperPricingFuncType = ThunkAction<Promise<WallpaperPricingResponse | void>, State, unknown, AnyAction>;

export default function fetchWallpaperPricing(): ThunkAction<Promise<WallpaperPricingResponse | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const {routesParams: {designId}, routesProps: {productType}} = state.routingData;

    if (productType !== WALLPAPER || state.addToCart.isFetching) return Promise.resolve();

    dispatch(wallpaperPricingFetching());

    const {
      country,
      currency
    } = queryParamsOverrideUserPreferences(state);

    const params: WallpaperPricingParams = {
      measurement_system: IMPERIAL,
      design_id: designId,
      shipping_country: country,
      [CURRENCY]: currency,
      quantity: state.addToCart.quantity || quantityFromOrderItem(state) || 1,
    };
    const substrateCode = state.addToCart.selectedSubstrate;
    const defaultWallpaperCode = get(state.wallpapers, 'default', '');
    const wallpaper = substrateCode ? substrateCode : defaultWallpaperCode;

    return request<WallpaperPricingResponse>(state, {
      url: `${selectAlpenroseHost(state)}/wallpapers/prices/${wallpaper}`,
      params
    })
      .then(({data}) => {
        dispatch(wallpaperPricingReceived(data));

        return data;
      })
      .catch((error: RequestError) => {
        dispatch(wallpaperPricingFail());
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(redirectCodes.includes(error.status) ? new RequireRedirectError(error, '/', 'fetchWallpaperPricing') : error));
        }
        console.log('Error caught in the `fetchWallpaperPricing` function', error); // eslint-disable-line
      });
  };
}
