import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {homeGoodPricingFail, homeGoodPricingFetching, homeGoodPricingReceived} from '../../../actions/homeGood';
import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {redirectCodes} from '../../../constants/redirectCodes';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';
import {
  quantityFromOrderItem,
  queryParamsOverrideUserPreferences,
  selectedHomeGoodFabric
} from '../../../utils/stateToPropsHelpers';

import {HomeGoodPricingResponse} from './fetchHomeGoodPricing';


interface HomeGoodPricingParams {
  measurement_system: State['user']['preferences']['measurement_system'];
  design_id: string | number;
  shipping_country: State['user']['preferences']['country'];
  currency: State['user']['preferences']['currency'];
  quantity: State['addToCart']['quantity'];
  fabric: string | undefined;
}


export default function fetchPricingForHomeGoodUsingSubstrateCode(): ThunkAction<Promise<HomeGoodPricingResponse | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();

    const {routingData: {routesParams: {designId}, routesProps: {productType}}} = state;

    const {country, measurementSystem, currency} = queryParamsOverrideUserPreferences(state);
    const fabric = selectedHomeGoodFabric(state);
    const params: HomeGoodPricingParams = {
      currency,
      design_id: parseInt(designId, 10) || designId,
      fabric,
      measurement_system: measurementSystem,
      quantity: state.addToCart.quantity || quantityFromOrderItem(state) || 1,
      shipping_country: country,
    };

    dispatch(homeGoodPricingFetching());

    return request<HomeGoodPricingResponse>(state, {
      url: `${selectAlpenroseHost(state)}/home_goods/prices/${productType}`,
      params
    })
      .then(({data}) => {
        dispatch(homeGoodPricingReceived(data));

        return data;
      })
      .catch((error: RequestError) => {
        dispatch(homeGoodPricingFail());
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(redirectCodes.includes(error.status) ? new RequireRedirectError(error, '/', 'fetchPricingForHomeGoodUsingSubstrateCode') : error));
        }
        console.log('Error caught in the `fetchPricingForHomeGoodUsingSubstrateCode` function', error); // eslint-disable-line
      });
  };
}
