import {Action} from 'redux';

import * as types from '../constants/ActionTypes';
import {ColorwaysData} from '../shapes/colorways';
import {ActionCreator, PayloadAction, PayloadActionCreator} from '../types/redux';


const ColorwaysDataFetchingActionType = `${types.COLORWAYS_GET}${types.FETCHING}` as const;

type TColorwaysDataFetchingActionType = typeof ColorwaysDataFetchingActionType;
export type ColorwaysDataFetchingAction = Action<TColorwaysDataFetchingActionType>;

const ColorwaysFailActionType = `${types.COLORWAYS_GET}${types.FAIL}` as const;

type TColorwaysFailActionType = typeof ColorwaysFailActionType;
export type ColorwaysFailAction = Action<TColorwaysFailActionType>;

const ColorwaysDataReceivedActionType = `${types.COLORWAYS_GET}${types.SUCCESS}` as const;

type TColorwaysDataReceivedActionType = typeof ColorwaysDataReceivedActionType;
export type ColorwaysDataReceivedAction = PayloadAction<TColorwaysDataReceivedActionType, ColorwaysData>;

const ResetColorwaysActionType = types.COLORWAYS_RESET;

type TResetColorwaysActionType = typeof ResetColorwaysActionType;
export type ResetColorwaysAction = Action<TResetColorwaysActionType>;

export const colorwaysFetching: ActionCreator<ColorwaysDataFetchingAction> = () => ({
  type: ColorwaysDataFetchingActionType
});

export const colorwaysFail: ActionCreator<ColorwaysFailAction> = () => ({
  type: ColorwaysFailActionType
});

export const resetColorways: ActionCreator<ResetColorwaysAction> = () => ({
  type: ResetColorwaysActionType
});

export const colorwaysReceived: PayloadActionCreator<ColorwaysDataReceivedAction> = (payload) => ({
  type: ColorwaysDataReceivedActionType,
  payload
});

export type ColorwaysAction =
  | ColorwaysDataFetchingAction
  | ColorwaysFailAction
  | ColorwaysDataReceivedAction
  | ResetColorwaysAction
