import * as types from '../constants/ActionTypes';

export function wallpapersFetching() {
  return {
    type: types.WALLPAPERS_GET + types.FETCHING
  };
}

export function wallpapersReceived(payload) {
  return {
    type: types.WALLPAPERS_GET + types.SUCCESS,
    payload
  };
}

export function wallpapersFail() {
  return {
    type: types.WALLPAPERS_GET + types.FAIL
  };
}

export function wallpaperPricingFetching() {
  return {
    type: types.WALLPAPER_PRICING_GET + types.FETCHING
  };
}

export function wallpaperPricingFail() {
  return {
    type: types.WALLPAPER_PRICING_GET + types.FAIL
  };
}

export function wallpaperPricingReceived(payload) {
  return {
    type: types.WALLPAPER_PRICING_GET + types.SUCCESS,
    payload
  };
}

export function calculatorLandingErrorReset() {
  return {
    type: types.CALCULATOR_LANDING_RESET
  };
}
