import * as types from '../constants/ActionTypes';


export function userFavoriteDesignsFetching() {
  return {
    type: types.USER_FAVORITE_DESIGNS_FETCHING,
  };
}

export function userFavoriteDesignsReceived(payload) {
  return {
    type: types.USER_FAVORITE_DESIGNS_RECEIVED,
    payload
  };
}

export function userFavoriteDesignsError() {
  return {
    type: types.USER_FAVORITE_DESIGNS_ERROR
  };
}
