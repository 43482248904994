import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {fabricsFail, fabricsFetching, fabricsReceived} from '../../../actions/fabrics';
import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {FetchFabricsResponse} from '../../../entities/fabrics/types/FetchFabricsResponse';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {queryParamsOverrideUserPreferences} from '../../../utils/stateToPropsHelpers';


export function fetchFabrics(): ThunkAction<Promise<void | FetchFabricsResponse>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const {country, measurementSystem, currency} = queryParamsOverrideUserPreferences(state);

    dispatch(fabricsFetching());

    return request<FetchFabricsResponse>(state, {
      url: `${selectAlpenroseHost(state)}/fabrics`,
      params: {
        measurement_system: measurementSystem,
        currency,
        shipping_country: country
      },
    })
      .then(({data}) => {
        dispatch(fabricsReceived(data));

        return data;
      })
      .catch((e: RequestError) => {
        dispatch(fabricsFail());
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(e));
        } else {
          // if (e.status === '404') {
          //   // todo this should not work on server check it
          //   fetchMiddleware.catchUnhandledFetchRequestFailureAndShowFlash();
          // } else
          console.log('Error caught in the `fetchFabrics` function', e); // eslint-disable-line
        }
      });
  };
}
