import {AxiosResponse} from 'axios';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {SOLID} from '../../../constants/Codes';
import {redirectCodes} from '../../../constants/redirectCodes';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {AppLocalities} from '../../../entities/pageSetup/countries/types';
import {MultiLocaleRouterConfig} from '../../../server/bootstrap/loadMultiLocaleRouterConfig/MultiLocaleRouterConfigTypes';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {RequireRedirectError} from '../../../utils/redirectError';


interface ProductSlugsResponse {
  data: {
    routes: [
      {
        name: string;
        lang: AppLocalities;
        path: string;
        path_with_slug: string;
      },
    ];
  };
}

export default function fetchProductSlugTranslations(): ThunkAction<Promise<MultiLocaleRouterConfig | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const {routesParams, routesProps} = state.routingData;

    // we don't need to do this request for Solid PDP
    if (routesProps.productType === SOLID) return Promise.resolve();

    const productType = routesProps.productType.toLowerCase();
    const designId = parseInt(routesParams?.designId, 10);
    const pageKey = state.pageSetup.pageKey;


    return request<ProductSlugsResponse>(state, {
      url: `${selectAlpenroseHost(state)}/multi_locale_route/${productType}/${designId}`
    })
      .then(({data: dataObj}: AxiosResponse<ProductSlugsResponse>) => {
        const {routes} = dataObj.data;
        const config: MultiLocaleRouterConfig = {
          [pageKey]: {}
        };

        for (let i = 0; i < routes.length; i++) {
          const {lang, path_with_slug} = routes[i];

          config[pageKey][lang] = path_with_slug;
        }

        return config;
      })
      .catch((error: RequestError) => {
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(error));
        } else {
          console.log('Error caught in the `fetchProductSlugTranslations` function', error); // eslint-disable-line
        }
      });
  };
}
